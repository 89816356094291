<script>
export default {
  props: {
    accept: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [File, Array],
      default: () => [],
    },
  },
  data: function () {
    return {
      innerValue: [],
    };
  },
  computed: {
    activatorProps() {
      return {
        disabled: this.disabled,
        label: this.label,
        onclick: () => this.open(),
      };
    },
    selectedFiles: {
      get() {
        return this.innerValue;
      },
      set(files) {
        const safe = Array.from(files);
        this.innerValue = safe;
        this.$emit('update:value', this.innerValue);
      },
    },
  },
  methods: {
    onInputChange(changeEvent) {
      this.selectedFiles = changeEvent.target.files;
      changeEvent.target.value = "";
    },
    open() {
      this.$refs.input?.click();
    },
    removeFile(index) {
      if (this.selectedFiles[index]) {
        this.selectedFiles.splice(index, 1);
      }
    },
  },
};
</script>

<template>
  <div>
    <input
      ref="input"
      :accept="accept"
      :disabled="disabled"
      :multiple="multiple"
      type="file"
      @change="onInputChange"
    />
    <slot name="activator" :props="activatorProps">
      <span>{{ label }}</span>
      <v-btn block depressed :disabled="disabled" @click="open">
        <v-icon left>mdi-upload</v-icon>Selecionar arquivo
      </v-btn>
    </slot>
    <slot :files="selectedFiles">
      <v-card
        v-for="(file, key) in selectedFiles"
        :key="key"
        elevation="0"
        class="mt-2"
        outlined
      >
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-card-text v-on="on">
              <span class="v-label-input text-truncate">
                <v-icon>mdi-file-document</v-icon>
                <span>{{ file.name }}</span>
              </span>
              <v-btn small icon @click="removeFile(key)">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-card-text>
          </template>
          
          <span>{{ file.name  }}</span>
        </v-tooltip>
      </v-card>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}

.v-card__text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .v-label-input.text-truncate .v-icon {
    margin-right: .5rem;
  }
}
</style>
